<template>
  <h1>{{ textMessage }}</h1>
</template>
<script>
import toolFunction from '@/utils/toolFunction.js'
import { signIn } from '@/utils/oidc'
export default {
  data() {
    return {
      textMessage: '正在退出登录....'
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      // 清除 token 、菜单、租户列表
      toolFunction.$sysTemInfo.cleanSystemInfo()
      this.goHome()
    },
    goHome() {
      const tenant = toolFunction.$sysTemInfo.getTenant()
      // const tenantPlat = toolFunction.$sysTemInfo.getTenantPlat()
      // const url = location.origin + `/navigation-display?tenant=${tenant || ''}`
      // location.href= url
      this.$router.push({
        path: '/navigation-display',
        query: {
          tenant
        }
      })
    },
    jumpRoute() {
      const tenant = toolFunction.$sysTemInfo.getTenant()
      const tenantPlat = toolFunction.$sysTemInfo.getTenantPlat()
      const signOutAfterIsReSign = toolFunction.$sysTemInfo.getSignOutAfterIsReSign()
      // 判断是否是saas还是paas
      // 跳转回登录之前的界面去path,目前简单做是跳回首页
      if (signOutAfterIsReSign) {
        toolFunction.$sysTemInfo.removeSignOutAfterIsReSign()
        signIn()
      } else {
        this.$router.push({
          path: '/'
        })
      }
    }
  }
}
</script>
