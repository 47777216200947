
import { defineComponent } from 'vue'
import toolFunction from '@/utils/toolFunction.js'
import { signIn } from '@/utils/oidc'
import { useRoute } from 'vue-router'
export default defineComponent({
  data() {
    return {
      textMessage: '正在登录...'
    }
  },
  mounted() {
    const route = useRoute()
    const query = route.query
    const { tenant, signinCallbackUrl } = query
    if (tenant) {
      // 设置租户
      toolFunction.$sysTemInfo.setTenant(tenant)
    }
    // 设置平台
    if (signinCallbackUrl) {
      // 设置登录后要跳转的地址
      toolFunction.$sysTemInfo.setSigninCallbackUrl(decodeURIComponent(String(signinCallbackUrl)))
    }
    signIn()
  },
  methods: {}
})
