
import { defineComponent } from 'vue'
import jwtDecode from 'jwt-decode'
import toolFunction from '@/utils/toolFunction.js'
import { IdecodeTokenInfo, IuserInfo } from '@/types/index'
import { message } from 'ant-design-vue'
import systemInfo from '@/utils/systemInfo'
import { checkUserAppRight } from '@/api/apiManage'
export default defineComponent({
  data() {
    return {
      textMessage: '正在验证...'
    }
  },
  mounted() {
    const { access_token } = toolFunction.transferUrlQueryStrToObj(
      decodeURIComponent(location.hash)
    )
    if (!access_token) {
      message.error('路由上没token!')
      return
    }
    this.init()
  },
  methods: {
    async init() {
      this.handleTokenInfo()
      this.jumpRoute()
    },

    // 处理token信息,设置到缓存中去
    handleTokenInfo() {
      const { id_token, access_token, token_type } = toolFunction.transferUrlQueryStrToObj(
        decodeURIComponent(location.hash)
      )

      if (access_token) {
        toolFunction.$sysTemInfo.setLoginOidcStatus(1)
      } else {
        toolFunction.$sysTemInfo.removeLoginOidcStatus()
      }
      if (!access_token) {
        this.textMessage = '登录验证失败!'
        return
      } else {
        this.textMessage = '登录成功,正在跳转...'
      }
      if (id_token) {
        toolFunction.$sysTemInfo.setIdToken(id_token)
      } else {
        toolFunction.$sysTemInfo.removeIdToken(id_token)
      }
      const decodeTokenInfo: IdecodeTokenInfo = jwtDecode(access_token)
      const { sub, tenant_id, tenant_code, name, tenant_plat } = decodeTokenInfo

      let token = token_type + ' ' + access_token
      toolFunction.setTokenRefreshToken(token, 'none')
      systemInfo.setTenantPlat(tenant_plat || 'saas')
      // 设置userInfo
      var data: IuserInfo = {
        company_code: '',
        username: '',
        company_id: '',
        company_name: '',
        tenant_id: '',
        tenant_code: '',
        tenant: '',
        user_id: '',
        tenant_plat: ''
      }
      data.company_code = ''
      data.username = name
      data.company_id = ''
      data.company_name = ''
      data.tenant_id = tenant_id
      data.tenant_code = tenant_code
      data.tenant = tenant_code
      data.user_id = sub
      data.tenant_plat = tenant_plat
      toolFunction.setUserInfo(data)
      toolFunction.$sysTemInfo.setTenant(tenant_code || '')
      return data
    },
    // 先判断是否有配置 需要检查用户app权限
    async checkUserAppRight() {
      const res = await checkUserAppRight()
      return res
    },
    goHome() {
      const tenant = systemInfo.getTenant()
      // const tenantPlat = systemInfo.getTenantPlat()

      this.$router.push({
        path: '/navigation-display',
        query: {
          tenant
        }
      })
    },
    jumppage() {
      const signinCallbackUrl = toolFunction.$sysTemInfo.getSigninCallbackUrl()
      if (signinCallbackUrl) {
        console.log('判断是否是saas还是paas================')
        toolFunction.$sysTemInfo.removeSigninCallbackUrl()
        window.open(signinCallbackUrl, '_self')
      } else {
        this.$router.push({
          path: '/'
        })
      }
    },
    async jumpRoute() {
      // this.goHome()
      // return
      const is = toolFunction.getUserRightAppCheckout()
      if (is) {
        const result = await this.checkUserAppRight()
        if (!result.data) {// 跳转到一个界面去 提示他先去新增app权限

          sessionStorage['hasUserAppRight'] = '0'
          this.$router.push({
            path: '/needUserAppRight'
          })
        } else {
          sessionStorage['hasUserAppRight'] = '1'
          this.goHome()
        }
      } else {
        this.goHome()
      }

    }
  }
})
